import React,{useState,useRef,useLayoutEffect, useEffect, useCallback} from 'react';
import './App.css';
import { SwapRightOutlined,UnorderedListOutlined } from '@ant-design/icons';
import { Layout, Space,Button,FloatButton,Spin } from 'antd'
import Slider from "react-slick";
import { Intro,Cion,Location,Dome,Deve,Doc,Phone,About } from './icon'
import { Line,Title } from './component'
// import {data} from './data'
import Img from './img'
//在头部引入css
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './scss/setStyle.scss'
import './scss/common.scss'

function ChangeSize(){
  const [size,setSize] = useState({
    width:document.documentElement.clientWidth+4,
    height:document.documentElement.clientHeight
  })
  const onResize = useCallback(()=>{
    setSize({
      width:document.documentElement.clientWidth+4,
    height:document.documentElement.clientHeight
    })
  },[])
  useEffect(()=>{
    // 监听
    window.addEventListener('resize',onResize)
    // 销毁
    return ()=>window.removeEventListener('resize',onResize)
  },[])
  // console.log(size)
  return size;
}

const { Header, Footer, Content } = Layout;
const headerStyle: React.CSSProperties = {
  textAlign: 'center',
  color: '#fff',
  height: 61,
  paddingInline: 50,
  lineHeight: '64px',
  backgroundColor: '#222222',
  borderBottom: '3px solid #999'
};

const contentStyle: React.CSSProperties = {
  textAlign: 'center',
  minHeight: 120,
  lineHeight: '120px',
  color: '#333',
  backgroundColor: '#fff',
};

// header component --start
function MenuItemOption(props:any){
  const child = props.children
  let itemShow = props.isshow == 'show' ? 'block': 'none'
  const mouseLeave = ()=>{
      props.onHide()
  }
  const List = child.map((item:any)=> <li key={item.label}><a href={item.link} target="_blank">{item.label}</a></li>)
  return(
    <ul style={{display:itemShow}} onMouseLeave={mouseLeave} className='menu-item-option'>{List}</ul>
  )
}
function MenuItem(props:any){
  const {label,anchor,icon,children} = props.value
  const size = ChangeSize()
  let [itemDisplay,setItemDisplay] = useState('hide')
  function Show(){
    if(children && itemDisplay == 'hide'){
      setItemDisplay(itemDisplay = 'show')
    }
  }
  function Hide(){
    if(children && itemDisplay == 'show'){
      setItemDisplay(itemDisplay = 'hide')
    }
  }
  const childHide =()=>{
    setItemDisplay(itemDisplay = 'hide')
  }
  return (
    <li className='menu-item' onMouseOver={Show} onMouseOut={Hide}><a href={anchor}>{icon}{label}</a>
    {/* 这里是判断是否有children属性 */}
    {(children && size.width>980) && <MenuItemOption isshow={itemDisplay} children={children} onHide={childHide}/>}
    </li>
  )
}
function Menu(){
  const menuData =[
    {label:'公司介绍',anchor:'#introduce',icon:<Intro/>},
    {label:'机器学习',anchor:'#machineLearning',icon:<Cion/>},
    {label:'地理信息',anchor:'#gis',icon:<Location/>},
    {label:'数据展示',anchor:'#data',icon:<Dome/>},
    {label:'开发服务',anchor:'#service',icon:<Deve/>},
    {label:'文档中心',anchor:'#doc',icon:<Doc/>},
    {label:'移动应用',anchor:'#mobileApp',icon:<Phone/>},
    {label:'关于我们',anchor:'#aboutUs',icon:<About/>}
  ]
  const Item = menuData.map((item:any)=> <MenuItem key={item.anchor} value={item}/>)
  return (
    <ul className='menuStyle'>{Item}</ul>
    
  )
}
// header compontent --end__________

// intro compontent --start______________
function IntroC(props:any){
  const {intro,intro_list,intro_item} = props.data
  const title = {en:'KNOW ABOUT US',ch:'城室科技'}
  const List = intro_list.map((li:any)=>
    <div className='intro-item' key={li.title_i}>
      <img src={li.icon} alt="" />
      <div>
        <h4>{li.title_i}</h4>
        <p>{li.intro_i}</p>
      </div>
    </div>
  )
  const [isHovering, setIsHovering] = useState(false);
  const [num,setnumber] = useState(0)
  const handleMouseEnter = (key:any) => {
    setnumber(key)
    setIsHovering(true);
  };

  const handleMouseLeave = (key:any) => {
    setnumber(0)
    setIsHovering(false);
  };
  const Item = intro_item.map((item:any,index:number)=>
    <div className='intro-item' key={index}
      onMouseEnter={()=>handleMouseEnter(index)}
      onMouseLeave={()=>handleMouseLeave(index)}
    >
      <img src={isHovering&& num==index ? intro_item[num].a_icon : item.icon} alt="" />
      <h5>{item.title_c}</h5>
      <span>{item.title_e}</span>
      <p>{item.intro}</p>
    </div>
  )
  return(
    <div className='intro-w' id='introduce'>
      <div className='intro-c mainStyle'>
        <div className='intro-t-w'>
          <img src={Img.intro} alt="" className='intro-t-img' />
          <div className='intro-t'>
            <h3>{title.en}</h3>
            <Line/>
            <h2>{title.ch}</h2>
            <p style={{marginTop:'30px'}}>{intro}</p>
            <div>{List}</div>
          </div>
        </div>
        <div className='intro-i-w'>
          {Item}
        </div>
      </div>
    </div>
  )
}
// intro compontent --end____________

// 机器学习 ML compontent --start
function MLItem(props:any){
  const {title,icon,intro,link,activate} = props.data
  return (
    <div className='ml-item'>
      <h5>{title}</h5>
      <div className='img-b'><img src={icon} alt="" /></div>
      <p>{intro}</p>
      {
      activate ? 
      <Button shape="round" size='large' href={link} target="_blank">
        功能体验<SwapRightOutlined className='icon-style'/>
      </Button> :
      <Button shape="round" size='large'>
        内部使用
      </Button>
      }
      
    </div>
  )
}
// 不参与轮播的list
function NOMLCAROUSEL(props:any){
  const {list,amount} = props
  let Item = list.map((item:any,i:number)=> (i<amount && <MLItem key={i} data={item}/>))
  return(
    <div className='no-carousel'>{Item}</div>
  )
}
// 参与轮播的list
function MLCAROUSEL(props:any){
  const {list,amount,num} = props
  
  let size = ChangeSize()
  const [width,setWidth] = useState(0)
  useLayoutEffect(()=>{
    if(size.width >=1440){
      setWidth(()=>{
        return 1440
      })
    }
    if(size.width>=1024&&size.width <1440){
      setWidth(()=>{
        return 1000
      })
    }
    if(size.width>980&&size.width <1024){
      setWidth(()=>{
        return 800
      })
    }
    if(size.width<=980){
      setWidth(()=>{
        return 600
      })
    }
  })
  const settings = {
    dots: true,//是否显示下面的索引元素
    infinite: true,
    speed: 500,
    slidesToShow: num,
    slidesToScroll: 1,
    arrows:false,
  };
  const styles ={
    width: width + "px",margin:'0 auto'
  }
  let Item = list.map((item:any,i:number)=> (i>=amount && <MLItem key={i} data={item}/>))

  return(
    <div className='main-carousel ml-carousel'>
      <div style={styles}>
      <Slider {...settings}> {Item} </Slider>
      </div>
    </div>
  )
}
function MLC(props:any){
  const {list} = props.data
  const title ={en:'MACHINE LEARNING',ch:'机器学习'}
  let size = ChangeSize()
  let [amount,setAmount] = useState(0)
  let [num,setNum] = useState(0)
  useLayoutEffect(()=>{
    if(size.width >=1440 && list.length<=8){
      setAmount(()=>{
        return list.length
      })
    }else if(size.width >=1440 && list.length<8){
      setAmount(()=>{
        return 4
      })
      setNum(()=>{
        return 4
      })
    }
    if(size.width>=1024&&size.width <1440){
      setAmount(()=>{
        return 3
      })
      setNum(()=>{
        return 3
      })
    }
    if(size.width>980&&size.width <1024){
      setAmount(()=>{
        return 2
      })
      setNum(()=>{
        return 2
      })
    }
    if(size.width<=980){
      setAmount(()=>{
        return 0
      })
      setNum(()=>{
        return 1
      })
    }
  })
  return (
    <div className='ml-w' id='machineLearning'>
      <div className='ml-c mainStyle'>
        <Title value={title}/>
        <NOMLCAROUSEL list={list} amount={amount}/>
        {/* 如果list的长度超过了8就可以轮播 */}
        {list.length > 8 || size.width <1440 && <MLCAROUSEL list={list} amount={amount} num={num}/>}
      </div>
    </div>
  )
}
// 机器学习 CML component --end___________

// 地理信息 GIS component --start
function GISItem(props:any){
  const {icon,title,intro,link,activate} = props.data
  return (
    <div className='gis-item'>
      <div className='img-b'><img src={icon} alt="" /></div>
      <h5>{title}</h5>
      <p>{intro}</p>
      {activate ?
        <Button shape="round" size='large' href={link} target="_blank">
          立即使用<SwapRightOutlined className='icon-style'/>
        </Button> :
        <Button shape="round" size='large'>
         内部使用
        </Button>
      }
      
    </div>
  )
}
// 不参与轮播
function NOCarousel(props:any){
  const {list,amount} = props
  let Item = list.map((item:any,i:number)=> (i<amount && <GISItem key={i} data={item}/>))
  return(
    <div className='no-carousel'>{Item}</div> 
  )
}
// 参与轮播
function IGSCarousel(props:any){
  const {list,amount,num} = props
  let size = ChangeSize()
  const [width,setWidth] = useState(0)
  useLayoutEffect(()=>{
    if(size.width >=1440){
      setWidth(()=>{
        return 1440
      })
    }
    if(size.width>=1024&&size.width <1440){
      setWidth(()=>{
        return 1000
      })
    }
    if(size.width>980&&size.width <1024){
      setWidth(()=>{
        return 800
      })
    }
    if(size.width<=980){
      setWidth(()=>{
        return 600
      })
    }
  })
  const settings = {
      dots: true,//是否显示下面的索引元素
      infinite: true,
      speed: 500,
      slidesToShow: num,
      slidesToScroll: 1,
      arrows:false,
  };
  
  const styles ={
    width: width+'px',margin:'0 auto'
  }
  let Item = list.map((item:any,i:number)=> (i>=amount && <GISItem key={i} data={item}/>))
  return (
      <div className='gis-carousel main-carousel'>
      <div style={styles}>
        <Slider {...settings}>
          {Item}
        </Slider>
      </div>
    </div>
  )
}
function GISC(props:any){
  const {list} = props.data
  const title ={en:'GEOGRAPHIC INFORMATION SYSTEM',ch:'地理信息'}
  const intro = '城室科技拥有自主创新的地理信息技术，包括信息标注、位置查询、路网搜索、城市万格、等时圈和GIS工具等，帮助广大地理信息技术从业者获取前沿技术与应用成果。'
  let size = ChangeSize()
  let [amount,setAmount] = useState(0)
  let [num,setNum] = useState(0)
  useLayoutEffect(()=>{
    if(size.width >=1440){
      setAmount(()=>{
        return 3
      })
      setNum(()=>{
        return 4
      })
    }
    if(size.width>=1024&&size.width <1440){
      setAmount(()=>{
        return 2
      })
      setNum(()=>{
        return 3
      })
    }
    if(size.width>980&&size.width <1024){
      setAmount(()=>{
        return 1
      })
      setNum(()=>{
        return 2
      })
    }
    if(size.width<=980){
      setAmount(()=>{
        return 0
      })
      setNum(()=>{
        return 1
      })
    }
  })
  return (
    <div className='gis-w' id='gis'>
      <div className='gis-c mainStyle'>
        <NOCarousel list={list} amount={amount}/>
        <div className='gis-t'>
          <span>{title.en}</span>
          <h2>{title.ch}</h2>
          <Line/>
          <p>{intro}</p>
        </div>
        <IGSCarousel list={list} amount={amount} num={num}/>
      </div>
    </div>
  )
}
// 地理信息 GIS component --end

// 数据展示 DATA component --start
function DATAItem(props:any){
  const {icon,title,bgi,intro,introList,link,activate} = props.data
  const list = introList.map((i:string)=> <li key={i}>{i}</li>)
  const styles ={
    backgroundImage:'url('+bgi+')',
  }
  return(
    <div className='data-item' style={styles}>
      <img src={icon} alt="" />
      <h5>{title}</h5>
      <p>{intro}</p>
      <ul>{list}</ul>
      {activate ?
        <Button shape="round" size='large' href={link} target="_blank">
          了解更多<SwapRightOutlined className='icon-style'/>
        </Button> :
        <Button shape="round" size='large'>
          内部使用
        </Button>
      }
      
    </div>
  )
}
// 不参与轮播的数据
function DATANOCAROUSEL(props:any){
  const {list,amount} = props
  let Item = list.map((item:any,i:number)=> (i<amount && <DATAItem key={i} data={item}/>))
  return(
    <div className='no-carousel'>{Item}</div>
  )
}
// 参与轮播的数据
function DATACAROUSEL(props:any){
  const {list,amount,num} = props
  let size = ChangeSize()
  const [width,setWidth] = useState(0)
  useLayoutEffect(()=>{
    if(size.width >=1440){
      setWidth(()=>{
        return 1440
      })
    }
    if(size.width>=1024&&size.width <1440){
      setWidth(()=>{
        return 1000-4
      })
    }
    if(size.width>980&&size.width <1024){
      setWidth(()=>{
        return 800-4
      })
    }
    if(size.width<=980){
      setWidth(()=>{
        return 600
      })
    }
  })
  const settings = {
      dots: true,//是否显示下面的索引元素
      infinite: true,
      speed: 500,
      slidesToShow: num,
      slidesToScroll: 1
  };
  const styles ={
    width: width + "px",margin:'0 auto'
  }
  let Item = list.map((item:any,i:number)=> (i>=amount && <DATAItem key={i} data={item}/>))
  return(
    <div className='main-carousel data-carousel'>
      <div style={styles}>
        <Slider {...settings}>
          {Item}
        </Slider>
      </div>
    </div>
  )
}
function DATAC(props:any){
  const title ={en:'DATA',ch:'数据展示'}
  const {list} = props.data
  let size = ChangeSize()
  let [amount,setAmount] = useState(0)
  let [num,setNum] = useState(0)
  useLayoutEffect(()=>{
    if(size.width >=1440 && list.length<=8){
      setAmount(()=>{
        return list.length
      })
    }else if(size.width >=1440 && list.length<8){
      setAmount(()=>{
        return 4
      })
      setNum(()=>{
        return 4
      })
    }
    if(size.width>=1024&&size.width <1440){
      setAmount(()=>{
        return 3
      })
      setNum(()=>{
        return 3
      })
    }
    if(size.width>980&&size.width <1024){
      setAmount(()=>{
        return 2
      })
      setNum(()=>{
        return 2
      })
    }
    if(size.width<=980){
      setAmount(()=>{
        return 0
      })
      setNum(()=>{
        return 1
      })
    }
  })
  return (
    <div className='data-w' id='data'>
      <div className='data-c mainStyle'>
        <Title value={title}/>
        <DATANOCAROUSEL list={list} amount={amount}/>
        {list.length > 8 || size.width <1440 &&<DATACAROUSEL list={list} amount={amount} num={num}/>}
      </div>
    </div>
  )
}
// 数据展示 DATA component --end

// 开发服务 SERVER component --start
function SERVERItem(props:any){
  const {icon,a_icon,title,intro,link,activate} = props.data
  const [ch_t,en_t] = title.split("|")
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <div className='server-item'
    onMouseEnter={()=>handleMouseEnter()}
    onMouseLeave={()=>handleMouseLeave()}
    >
      <div className='flex-layout'>
        <div className='img-w'>
          <img src={isHovering? a_icon : icon} alt="" />
        </div>
        <div className='title-l'>
          <span>{en_t}</span>
          <h5>{ch_t}</h5>
        </div>
      </div>
      <p>{intro}</p>
      {activate ?
        <Button shape="round" size='large' href={link} target="_blank">
          了解更多<SwapRightOutlined className='icon-style'/>
        </Button>
        :
        <Button shape="round" size='large'>
          内部使用
        </Button>
      }
    </div>
  )
}
// 不参与轮播的item
function NOSERVERCAROUSEL(props:any){
  const {list,amount} = props
  let Item = list.map((item:any,i:number)=> (i<amount && <SERVERItem key={i} data={item}/>))
  return(
    <div className='no-carousel'>{Item}</div>
  )
}
// 参与轮播的item
function SERVERCAROUSEL(props:any){
  const {list,amount,num} = props
  let size = ChangeSize()
  const [width,setWidth] = useState(0)
  useLayoutEffect(()=>{
    if(size.width >=1440){
      setWidth(()=>{
        return 1440
      })
    }
    if(size.width>=1024&&size.width <1440){
      setWidth(()=>{
        return 1000-4
      })
    }
    if(size.width>980&&size.width <1024){
      setWidth(()=>{
        return 800-4
      })
    }
    if(size.width<=980){
      setWidth(()=>{
        return 600
      })
    }
  })
  const settings = {
      dots: true,//是否显示下面的索引元素
      infinite: true,
      speed: 500,
      slidesToShow: num,
      slidesToScroll: 1
  };
  const styles ={
    width: width + "px",margin:'0 auto'
  }
  let Item = list.map((item:any,i:number)=> (i>=amount && <SERVERItem key={i} data={item}/>))
  return(
    <div className='main-carousel server-carousel'>
      <div style={styles}>
        <Slider {...settings}>
          {Item}
        </Slider>
      </div>
    </div>
  )
}
function SERVERC(props:any){
  const {list} = props.data
  const title ={en:'SERVER',ch:'开发服务'}
  const intro = '城室科技专注于程序开发业务，经过多年发展，提供完善创新的开发服务，协助客户加速产品开发和升级，降低开发成本。'
  let size = ChangeSize()
  let [amount,setAmount] = useState(0)
  let [num,setNum] = useState(0)
  useLayoutEffect(()=>{
    if(size.width >=1440 && list.length<=8){
      setAmount(()=>{
        return list.length
      })
    }else if(size.width >=1440 && list.length<8){
      setAmount(()=>{
        return 4
      })
      setNum(()=>{
        return 4
      })
    }
    if(size.width>=1024&&size.width <1440){
      setAmount(()=>{
        return 3
      })
      setNum(()=>{
        return 3
      })
    }
    if(size.width>980&&size.width <1024){
      setAmount(()=>{
        return 2
      })
      setNum(()=>{
        return 2
      })
    }
    if(size.width<=980){
      setAmount(()=>{
        return 0
      })
      setNum(()=>{
        return 1
      })
    }
  })
  return (
    <div className='server-w' id='service'>
        <div className='server-t'>
          <Title value={title}/>
          <p className='server-intro'>{intro}</p>
        </div>
      <div className='server-c mainStyle'>
        <div className='server-item-w'>
          <NOSERVERCAROUSEL list={list} amount={amount}/>
          {list.length > 8 || size.width <1440 && <SERVERCAROUSEL list={list} amount={amount} num={num}/>}
        </div>
      </div>
    </div>
  )
}
// 开发服务 SERVER component --end

// 文档中心 DOC component --start
function DOCItem(props:any){
  const {icon,a_icon,title,intro,link} = props.data
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return(
    <div className='doc-item'>
        <a href={link} target="_blank" className='img-w'
          onMouseEnter={()=>handleMouseEnter()}
          onMouseLeave={()=>handleMouseLeave()}
          ><img src={isHovering? a_icon : icon} alt="" />
        </a> 
      <h5>{title}</h5>
      <p>{intro}</p>
    </div>
  )
}
function DOC(props:any){
  const title ={en:'DOC',ch:'文档中心'}
  const {list} = props.data
  const List = list.map((item:any,i:number)=> <DOCItem key={i} data={item}/>)
  return (
    <div className='doc-w' id='doc'>
        <Title value={title}/>
      <div className='doc-c mainStyle'>
        {List}
      </div>
    </div>
  )
}
// 文档中心 DOC component --start

// 移动应用 MOBILEAPP components --start
function MOBILEAPP(props:any){
  const {data} = props.data
  const title = {en:'MOBILE APP',ch:'移动应用'}
  return(
    <div className='mobile-w' id='mobileApp'>
      <div className='mobile-c mainStyle'>
        <img src={data.img} alt="" style={{width:'50%',height:'auto'}} className="mobile-img" />
        <div className='mobile-app'>
          <div className='mobile-t'>
            <span>{title.en}</span>
            <h3>{title.ch}</h3>
            <Line/>
          </div>
          <div className='mobile-item'>
            <img src={data.content.icon} alt="" />
            <div className='mobile-t'>
              <h5>{data.content.en_t}</h5>
              <p>{data.content.ch_t}</p>
            </div>
          </div>
            <p className='app-intro'>{data.content.intro}</p>
          <Button shape="round" size='large' href={data.content.href}>
            了解更多<SwapRightOutlined className='icon-style'/>
          </Button>
        </div>
      </div>
    </div>
  )
}
// 移动应用 MOBILEAPP components --end

// 关于我们 ABOUTUS component -- start
function ABOUTItem(props:any){
  const {img,title,intro,link} = props.data
  return(
    <div className='about-item'>
      <img src={img} alt="" />
      <span>{title}</span>
      <p>{intro}</p>
      <Button shape="round" size='large' href={link} target="_blank">
        了解更多<SwapRightOutlined className='icon-style'/>
      </Button>
    </div>
  )
}
function ABOUTCarousel(props:any){
  const {list,amount} = props
  let size = ChangeSize()
  const [width,setWidth] = useState(0)
  useLayoutEffect(()=>{
    if(size.width >=1024){
      setWidth(()=>{
        return 1000
      })
    }
    if(size.width >980 &&size.width<1024){
      setWidth(()=>{
        return 800
      })
    }
    if(size.width <=980){
      setWidth(()=>{
        return 600
      })
    }
  })
  const settings = {
      dots: true,//是否显示下面的索引元素
      infinite: true,
      speed: 500,
      slidesToShow: amount,
      slidesToScroll: 1,
      arrows:false,
  };
  const styles ={
    width: width + "px",margin:'0 auto'
  }
  let Item = list.map((item:any,i:number)=> <ABOUTItem key={i} data={item}/>)
  return(
    <div className='about-carousel main-carousel'>
      <div style={styles}>
        <Slider {...settings}>
          {Item}
        </Slider>
      </div>
    </div>
  )
}
function ABOUTUS(props:any){
  const {list} = props.data
  const title ={en:'ABOUT US',ch:'关于我们'}
  let size = ChangeSize()
  let [amount,setAmount] = useState(0)
  useLayoutEffect(()=>{
    if(size.width >=1024){
      setAmount(()=>{
        return 3
      })
    }
    if(size.width >980 &&size.width<1024){
      setAmount(()=>{
        return 2
      })
    }
    if(size.width <=980){
      setAmount(()=>{
        return 1
      })
    }
  })
  return(
    <div className='about-w' id='aboutUs'>
      <div className='about-c mainStyle'>
        <Title value={title}/>
        <ABOUTCarousel list={list} amount={amount}/>
      </div>
    </div>
  )
}
// 关于我们 ABOUTUS component -- end

// 合作伙伴 PARTNERS component -- start
function PRATNERSC(){
  const data ={
    title:{en_t:'PARTNERS',ch_t:'合作伙伴'},
    imgList:[
      'https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/8%20aboutus/aboutus_img_partner1_default.png',
      'https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/8%20aboutus/aboutus_img_partner2_default.png',
      'https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/8%20aboutus/aboutus_img_partner3_default.png',
    ],
    less980:[
      'https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/8%20aboutus/aboutus_img_partner1_mobile%402x.png',
      'https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/8%20aboutus/aboutus_img_partner2_mobile%402x.png',
      'https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/8%20aboutus/aboutus_img_partner3_mobile%402x.png',
      'https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/8%20aboutus/aboutus_img_partner4_mobile%402x.png',
      'https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/8%20aboutus/aboutus_img_partner5_mobile%402x.png',
      'https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/8%20aboutus/aboutus_img_partner6_mobile%402x.png',
      'https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/8%20aboutus/aboutus_img_partner7_mobile%402x.png',
      'https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/8%20aboutus/aboutus_img_partner8_mobile%402x.png',
      'https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/8%20aboutus/aboutus_img_partner9_mobile%402x.png',
    ]
  }
  const size = ChangeSize()
  const ImgList = (size.width>980 ? 
    data.imgList.map((item:any,i:number)=> <div className='img-w' key={i}><img src={item} alt=""/></div>)
    :
    data.less980.map((item:any,i:number)=> <div className='img-w' key={i}><img src={item} alt=""/></div>))
  // const [width,setWidth] = useState(100)
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    arrows:false,
    dots: true,//是否显示下面的索引元素
  };
  const styles ={
    width: 100 + "%",margin:'0 auto',overflow: "hidden"
  }
  return(
    <div className='partners-w'>
      <div className='partners-t'>
        <h5>{data.title.en_t}</h5>
        <h4>{data.title.ch_t}</h4>
      </div>
      <div style={styles} className='main-carousel partners-carousel'>
        <Slider {...settings}> {ImgList} </Slider>
      </div>
    </div>
  )
}
// 合作伙伴 PARTNERS component -- end

// 团队 TEAMC component -- start
function TEAMItem(props:any){
  const {img,name,intro} = props.data
  return(
    <div className='team-item'>
      <div><img src={img}/></div>
      <div className='team-t'>
      <h5>{name}</h5>
      <p>{intro}</p>
      </div>
    </div>
  )
}
function TEAMC(props:any){
  const {list} = props.data
  const title={en:'CODE TEAM',ch:'核心团队'}
  let size = ChangeSize()
  let [num,setNum] = useState(0)
  useLayoutEffect(()=>{
    if(size.width>1024){
      setNum(()=>{
        return 3
      })
    }else{
      setNum(()=>{
        return 1
      })
    }
  })
  const settings = {
    dots: true,//是否显示下面的索引元素
    className: "center",
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: num,
    slidesToScroll: 1,
    arrows:false,
};
  const Item = list.map((item:any,i:number)=> <TEAMItem key={i} data={item}/>)
  return(
    <div className='team-w'>
      <div className='team-t'>
        <h5>{title.en}</h5>
        <h4>{title.ch}</h4>
      </div>
      <div className='team-carousel' style={{}}>
        <Slider {...settings}> {Item} </Slider>
      </div>
    </div>
  )
}
// 团队 TEAMC component -- end

// footer component --start
function FContent(){
  const data ={
    logo:'https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/9%20footer/footer_logo_citorytech_default.png',
    ch_name:'苏州城室科技有限公司',
    en_name:'CITORYTECH CO LTD',
    recommend:'城室科技团队成立于2017年，创业自麻省理工学院。团队致力于研发基于图像(AI)+地理信息服务(GIS)的综合算法，服务智慧城市、地产咨询、新零售交互等行业。',
    
    intro:'公司简介',
    list:[
      {icon:'https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/9%20footer/footer_icon_home_default.png',title:'联系地址：',content:'苏州市高新区金山路龙湖中心大楼1004室'},
      {icon:'https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/9%20footer/footer_icon_phone_default.png',title:'联系电话：',content:'021-55801766'},
      {icon:'https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/9%20footer/footer_icon_Email_default.png',title:'电子邮箱：',content:'customer@citorytech.com'}
    ],

    sub_us:'订阅我们',
    info:'欢迎订阅我们，获得最新的更新和新闻。',
    img:'https://share.cdn.citory.tech/ZYC-UI/citorytech%20official%20website/9%20footer/footer_img_QRcode_default.png'
  }
  const List = data.list.map((item:any,i:number)=>
    <li key={i}>
      <img src={item.icon} alt="" />
      <div>
        <b>{item.title}</b>
        <p>{item.content}</p>
      </div>
    </li>
  )
  return (
    <div className='footer-w mainStyle'>
      <div className='footer-i footer-reco'>
        <div className='company-logo'>
          <img src={data.logo} alt="" />
          <div className='company-title'>
            <p>{data.ch_name}</p>
            <p>{data.en_name}</p>
          </div>
        </div>
        <p style={{lineHeight:'2em'}}>{data.recommend}</p>
      </div>
      <div className='footer-i footer-intro'>
        <h5>{data.intro}</h5>
        <ul>{List}</ul>
      </div>
      <div className='footer-i footer-sub'>
        <h5>{data.sub_us}</h5>
        <p>{data.info}</p>
        <img src={data.img} alt="" />
      </div>
    </div>
  )
}
// APP
function App() {
  const [data, setData]:any = useState(null);
  useEffect(() => {
    if(localStorage.getItem('data')){
      const str:any = localStorage.getItem('data')
      setData(JSON.parse(str));
    }else{
      fetch("https://share.cdn.citory.tech/cdn/citorytech_web_info.json")
      .then(response=>response.json())
      .then(res=>{
        setData(res);
        localStorage.setItem('data',JSON.stringify(res))
      })
    }
  }, []);
 
  const logo = 'https://share.cdn.citory.tech/ZYC-UI/logo%20for%20Citory/logo-white%204.svg'
  const videoSrc ='https://share.cdn.citory.tech/cases/Collection.mp4'
  const time:any = new Date()
  const year = time.getFullYear()
  
  const size = ChangeSize()
  let [isshow,setShow] = useState(false)
  function OnShow(){
    setShow(isshow= !isshow)
  }
  if (data === null) {
    // 如果数据尚未加载完成，可以显示 loading 或其他占位内容
    return (<div className="example">
      <Spin size="large" tip="载入中"/>
      </div>)
  }

  return (
    <div className="App">
      <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
        <Layout>
          <Header style={headerStyle}>
            <div className='header-w mainStyle'>
              <img src={logo}/>
              {(isshow || size.width>980)  && <Menu/>}
              <span className='menu-btn' onClick={OnShow}><UnorderedListOutlined /></span>
            </div>
          </Header>
          <Content style={contentStyle}>
            <div className='video-w'>
              <video src={videoSrc} loop autoPlay preload="auto" playsInline muted></video>
              <div className='text'>
                <h1>用图像对话世界</h1>
                <h2>MAPPING WITH IMAGE</h2>
              </div>
            </div>
            <IntroC data={data.companyIntro}/>
            <MLC data={data.ml}/>
            <GISC data={data.gis}/>
            <DATAC data={data.dataShow}/>
          </Content>
          <SERVERC data={data.devServe}/>
          <DOC data={data.doc}/>
          <MOBILEAPP data={data.mobileApp}/>
          <ABOUTUS data={data.aboutUS}/>
          <PRATNERSC/>
          <TEAMC data={data.codeTeam}/>
          <Footer>
            <FContent/>
            <p className='copyright'>Copyright © {year} 苏州城室科技有限公司 &nbsp;&nbsp;
            <a href="http://beian.miit.gov.cn" target='_blank'>苏ICP备2022038016号-2</a>
            {/* <a href="http://beian.miit.gov.cn" target='_blank'> 沪ICP备 19043655号</a>&nbsp;&nbsp; */}
            {/* <a href="http://beian.miit.gov.cn" target='_blank'> 沪ICP证 沪B2-20201286</a> */}
            </p>
          </Footer>
          <FloatButton.BackTop />
        </Layout>
      </Space>
    </div>
  );
}

export default App;
